import React, { useState, useEffect } from 'react';
import { Row, Col, Table, Button } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import AddWorkspaceModal from './AddWorkspaceModal';
import SearchBox from 'common/components/SearchBox/SearchBox';
import { getAllWorksapce } from 'workspace/api/workspace.service';
import { useLoader } from 'common/providers/LoaderProvider';
import { toastify } from 'common/components/Toastify';
import { formatDate } from 'common/utils';
import { useAuth } from 'workspace/providers/auth/AuthContext';
import { deleteWorkspace } from 'workspace/api/workspace.service';
import { useConfirmDialog } from 'common/components/ConfirmationDialog';
import './manage-workspace.css';

const RenderNoWorkspacesMessage = () => (
  <div className="no-matching-workspaces d-flex justify-content-center align-items-center" tabIndex="0">
    <FormattedMessage id="ws.noworkspacefound" defaultMessage="No workspaces found." />
  </div>
);

const RenderNoMatchingWorkspacesMessage = () => (
  <div className="no-matching-workspaces d-flex justify-content-center align-items-center" tabIndex="0">
    <FormattedMessage id="ws.noMatchingWorkspaceMessage" defaultMessage="No matching workspaces found." />
  </div>
);

const ManageWorkspace = () => {
  const intl = useIntl();
  const { openWorkspace, clearWorkspace } = useAuth();
  const { showLoader, hideLoader } = useLoader();
  const { showConfirmDialog } = useConfirmDialog();

  const [workspaceData, setWorkspaceData] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [showCreateWorkspace, setShowCreateWorkspace] = useState(false);
  const [editWorkspace, setEditWorkspace] = useState();

  useEffect(() => {
    // clear workspace data when manage workspace page loads
    clearWorkspace();

    // fetch all workspace data
    fetchWorkspaceData();
  }, []);

  const fetchWorkspaceData = async () => {
    try {
      showLoader();
      const data = await getAllWorksapce();
      setWorkspaceData(data || []);
    } catch (err) {
      handleFetchError(err);
    } finally {
      hideLoader();
    }
  };

  const handleFetchError = err => {
    if (err.status === 404) {
      setWorkspaceData([]);
    } else {
      toastify.showErrorToast(intl.formatMessage({ id: 'ws.unabletofetchworkspace' }));
    }
  };

  const handleSearchChange = value => {
    setSearchTerm(value);
  };

  const handleCreateOrEditWorkspace = (workspace = null) => {
    setShowCreateWorkspace(true);
    setEditWorkspace(workspace);
  };

  const handleClose = () => {
    setShowCreateWorkspace(false);
    setEditWorkspace(null);
  };

  const handleOpenWorkspace = workspaceData => {
    openWorkspace(workspaceData);
  };

  const handleDeleteWorkspaceClick = workspace => {
    const workspaceName = workspace.name;
    showConfirmDialog({
      title: intl.formatMessage({ id: 'ws.modal.delete.confirmation.title' }),
      message: (
        <span>
          {intl.formatMessage(
            { id: 'ws.modal.delete.workspace.confirmation.message' },
            { workspaceName: <strong>{workspaceName}</strong> }
          )}
        </span>
      ),
      confirmText: intl.formatMessage({ id: 'message.delete' }),
      cancelText: intl.formatMessage({ id: 'message.cancel' }),
      onConfirm: () => handleDeleteWorkspaceConfirm(workspace),
    });
  };

  const handleDeleteWorkspaceConfirm = async selectedWorkspace => {
    try {
      await deleteWorkspace(selectedWorkspace.id);

      toastify.showSuccessToast(
        intl.formatMessage({ id: 'success.workspacedelete', defaultMessage: 'Workspace deleted successfully' })
      );
      fetchWorkspaceData();
    } catch (error) {
      handleDeleteError(error);
    }
  };

  const handleDeleteError = error => {
    if (error.status === 417) {
      toastify.showWarningToast(error.message, { className: 'toast-width-large' });
    } else {
      toastify.showErrorToast(
        intl.formatMessage({ id: 'error.workspacedelete', defaultMessage: 'Error deleting workspace' })
      );
    }
  };

  const filteredWorkspaces = workspaceData?.filter(workspace =>
    workspace.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="manage-workspace-container">
      <h4>
        <FormattedMessage id="ws.management" />
      </h4>
      <Row>
        <Col xs={12} className="workspace-list-actions-container">
          <div className="workspace-list-actions-left">
            <SearchBox searchText={searchTerm} placeholder="Search Workspace" onSearch={handleSearchChange} />
          </div>
          <div className="workspace-list-actions-right">
            <Button size="sm" onClick={() => handleCreateOrEditWorkspace()}>
              <FormattedMessage id="ws.createworkspace" />
            </Button>
          </div>
        </Col>
      </Row>

      <Row className="overflow-y-auto">
        {workspaceData?.length === 0 && <RenderNoWorkspacesMessage />}

        {workspaceData?.length > 0 && filteredWorkspaces?.length === 0 && <RenderNoMatchingWorkspacesMessage />}
        <Col>
          <Table striped bordered hover className="mt-3">
            <thead>
              <tr>
                <th>
                  <FormattedMessage id="ws.workspaceName" />
                </th>
                <th>
                  <FormattedMessage id="ws.workspaceDescription" />
                </th>
                <th style={{ width: '120px' }}>
                  <FormattedMessage id="ws.createddate" />
                </th>
                <th style={{ width: '120px' }}>
                  <FormattedMessage id="ws.modifiedDate" />
                </th>
                <th style={{ width: '183px' }}>
                  <FormattedMessage id="ws.actions" />
                </th>
              </tr>
            </thead>
            <tbody>
              {filteredWorkspaces?.map(workspace => (
                <tr key={workspace.id}>
                  <td className="workspace-name-td" title={workspace.name}>
                    {workspace.name}
                  </td>
                  <td className="workspace-description-td" title={workspace.description}>
                    {workspace.description}
                  </td>
                  <td>{formatDate(workspace.createdDate) || <FormattedMessage id="ws.book.na" />}</td>
                  <td>{formatDate(workspace.modifiedDate) || <FormattedMessage id="ws.book.na" />}</td>
                  <td>
                    <Button variant="primary" size="sm" className="mr-2" onClick={() => handleOpenWorkspace(workspace)}>
                      <FormattedMessage id="ws.open" />
                    </Button>
                    <Button
                      variant="primary"
                      size="sm"
                      className="mr-2"
                      onClick={() => handleCreateOrEditWorkspace(workspace)}
                    >
                      <FormattedMessage id="message.edit" />
                    </Button>
                    <Button variant="secondary" size="sm" onClick={() => handleDeleteWorkspaceClick(workspace)}>
                      <FormattedMessage id="message.delete" />
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>
      {showCreateWorkspace && (
        <AddWorkspaceModal handleClose={handleClose} reload={fetchWorkspaceData} editWorkspace={editWorkspace} />
      )}
    </div>
  );
};

export default ManageWorkspace;
