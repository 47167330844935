import { FormattedMessage } from 'react-intl';
import { Container, Nav, Navbar } from 'react-bootstrap';

const Header = ({ children, BackButton, hideHelp, workspaceName }) => {
  const HELP_LINK_ADDRESS = process.env.REACT_APP_HELP_LINK;

  const handleOpenHelpLink = () => {
    window.open(HELP_LINK_ADDRESS, '_blank', 'noopener,noreferrer');
  };

  return (
    <header className="App-header">
      <Navbar bg="light" data-bs-theme="light" expand="lg" className="bg-body-tertiary">
        <Container fluid>
          {BackButton}
          <Navbar.Brand className="brand">
            <h1 className="pearson-heading text-truncate" title={workspaceName}>
              <FormattedMessage id="pearsonheader.title" />
              {workspaceName ? ` - ${workspaceName}` : ''}
            </h1>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto"></Nav>
            <Nav>
              {children}
              {!hideHelp && (
                <Nav.Link
                  id="help-icon"
                  aria-label="help button to activate press enter"
                  onClick={() => {
                    handleOpenHelpLink();
                  }}
                >
                  <FormattedMessage id="helpText" />
                  &nbsp;
                  <i className="bi bi-question-circle"></i>
                </Nav.Link>
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  );
};

export default Header;
