import axios from 'axios';
import { extractErrorDetails } from 'common/utils';
import { publish } from 'common/utils/events';
import { refreshAuthToken } from './login.service';

// Create an Axios instance with default configuration
export const apiClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

// Request interceptor to handle any pre-request logic
apiClient.interceptors.request.use(config => {
  const isAuthEndpoints = config.url.includes('login') || config.url.includes('refresh-token');

  if (isAuthEndpoints) {
    // Remove Authorization header for login endpoint
    delete config.headers['Authorization'];
  } else {
    // Set Authorization header with Bearer token
    const token = localStorage.getItem('wstoken');
    config.headers['Authorization'] = `Bearer ${token}`;
  }

  return config;
});

// Response interceptor to handle responses and errors
apiClient.interceptors.response.use(
  response => response,
  async error => {
    const originalRequest = error.config;

    // If the error status is 401 and there is no originalRequest._retry flag,
    // it means the token has expired and we need to refresh it
    if (isTokenExpiredError(error, originalRequest)) {
      return handleTokenRefresh(originalRequest);
    }

    // Handle 404 Not Found Error (User  Does Not Exist)
    if (isUserNotFoundError(error)) {
      return handleUserNotFound();
    }

    // For all other errors, reject the promise
    return Promise.reject(error);
  }
);

/**
 * Handles the response of an API request.
 *
 * @param {Function} request - A function that returns a promise (the API request).
 * @returns {Promise<any>} - A promise that resolves with the response data.
 * @throws {Error} - Throws an error if the request fails.
 */
const handleResponse = async request => {
  try {
    const response = await request();
    return response?.data;
  } catch (error) {
    throw extractErrorDetails(error);
  }
};

/**
 * Checks if the error is due to an expired token.
 * @param {Object} error - The error object.
 * @param {Object} originalRequest - The original request configuration.
 * @returns {boolean} - True if the token is expired, false otherwise.
 */
function isTokenExpiredError(error, originalRequest) {
  return error?.response?.status === 401 && !originalRequest._retry;
}

/**
 * Handles token refresh logic.
 * @param {Object} originalRequest - The original request configuration.
 * @returns {Promise} - A promise that resolves to the retried request or stops the chain.
 */
async function handleTokenRefresh(originalRequest) {
  originalRequest._retry = true;

  try {
    const refreshToken = localStorage.getItem('wsRefreshToken');
    const { token } = await refreshAuthToken({ refreshToken });

    localStorage.setItem('wstoken', token);

    // Retry the original request with the new token
    originalRequest.headers.Authorization = `Bearer ${token}`;
    return axios(originalRequest);
  } catch (error) {
    // Handle refresh token error or redirect to login
    publish('ws:auth:logout');

    return new Promise(() => {}); // Stop the promise chain
  }
}

/**
 * Checks if the error is due to a user not found.
 * @param {Object} error - The error object.
 * @returns {boolean} - True if the user is not found, false otherwise.
 */
function isUserNotFoundError(error) {
  return error?.response?.status === 404 && error?.response?.data?.message === 'user does not exist';
}

/**
 * Handles user not found error by logging out the user.
 * @returns {Promise} - A promise that stops the chain.
 */
function handleUserNotFound() {
  publish('ws:auth:logout');

  return new Promise(() => {}); // Stop the promise chain
}

export default {
  get: (url, config) => handleResponse(() => apiClient.get(url, config)),
  post: (url, data, config) => handleResponse(() => apiClient.post(url, data, config)),
  put: (url, data, config) => handleResponse(() => apiClient.put(url, data, config)),
  delete: (url, config) => handleResponse(() => apiClient.delete(url, config)),
};
