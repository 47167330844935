import axios from './axios';

/**
 * Adds a new book.
 *
 * @param {Object} bookData - The data of the book to add.
 * @param {string} wsId - The ID of the workspace.
 * @returns {Promise} The Axios promise for the HTTP request.
 */
export async function addBook(bookData, wsId) {
  return axios.post(`ws/${wsId}/addBook`, bookData);
}

/**
 * Updates an existing book.
 *
 * @param {string} bookId - The ID of the book to update.
 * @param {Object} bookData - The updated data of the book.
 * @returns {Promise} The Axios promise for the HTTP request.
 */
export async function updateBook(bookId, bookData) {
  return axios.put(`/ws/books/${bookId}`, bookData);
}

/**
 * Retrieves a list of books.
 *
 * @param {boolean} showCard - Whether to show books as cards.
 * @param {string} wsId - The ID of the workspace.
 * @returns {Promise} The Axios promise for the HTTP request.
 */
export async function getBooks(showCard, wsId) {
  return axios.get(`ws/${wsId}/books${showCard ? '?showAsCard=true' : ''}`);
}

/**
 * Deletes a book.
 *
 * @param {string} bookId - The ID of the book to delete.
 * @returns {Promise} The Axios promise for the HTTP request.
 */
export async function deleteBook(bookId) {
  return axios.delete(`/ws/books/${bookId}`);
}

/**
 * Adds a list of selected books.
 *
 * @param {Array} selectedBooksArray - An array of selected book objects.
 * @returns {Promise} The Axios promise for the HTTP request.
 */
export async function addBookList(selectedBooksArray) {
  return axios.post('ws/books/cards', selectedBooksArray);
}

/**
 * Retrieves all book cards.
 *
 * @returns {Promise} The Axios promise for the HTTP request.
 */
export async function getAllCards() {
  return axios.get('ws/books/cards');
}

/**
 * Publishes a book.
 *
 * @param {string} bookId - The ID of the book to publish.
 * @returns {Promise} The Axios promise for the HTTP request.
 */
export async function publishBook(bookId) {
  return axios.post(`ws/books/${bookId}/publish`);
}

/**
 * Republish a book.
 *
 * @param {string} bookId - The ID of the book to republish.
 * @returns {Promise} The Axios promise for the HTTP request.
 */
export async function republishBook(bookId) {
  return axios.post(`ws/books/${bookId}/republish`);
}

/**
 * Unpublish a book.
 *
 * @param {string} bookId - The ID of the book to republish.
 * @returns {Promise} The Axios promise for the HTTP request.
 */
export async function unpublishBook(bookId) {
  return axios.post(`ws/books/${bookId}/unpublish`);
}

/**
 * Fetches the history of a book by its ID.
 *
 * @param {string} bookId - The ID of the book whose history is to be fetched.
 * @returns {Promise<Object>} A promise that resolves to the book history data.
 */
export async function getBookHistory(bookId) {
  return axios.get(`/ws/books/${bookId}/history`);
}
