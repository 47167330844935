import axios from './axios';

/**
 * Uploads a file to the server.
 *
 * @param {FormData} data - The FormData object containing the file to be uploaded.
 * @returns {Promise} - A promise that resolves to the response of the upload request.
 */
export const uploadFile = async data => {
  return axios.post('ws/images', data, { headers: { 'Content-Type': 'multipart/form-data' } });
};
