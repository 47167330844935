import { Button } from 'react-bootstrap';
import { Outlet, useLocation } from 'react-router-dom';
import ProfileModal from '../ProfileModal/ProfileModal';
import Header from 'common/components/layout/Header';
import WSTabs from 'workspace/components/layout/WSTabs';
import { useAuth } from 'workspace/providers/auth/AuthContext';
import { uploadFile } from 'workspace/api/file.service';
import { EditorRootProvider } from 'lib/rte/provider/EditorRootProvider';
import { ROLES } from 'workspace/constants';
import './layout.css';

const BackButton = () => {
  const { clearWorkspace } = useAuth();

  const handleBackToWorkspace = () => {
    clearWorkspace();
  };

  return (
    <Button
      variant="link"
      className="btn-back-to-workspace"
      onClick={handleBackToWorkspace}
      title="Back to Workspace Management"
    >
      <i className="fa fa-arrow-circle-left back-arrow-icon"></i>
    </Button>
  );
};

const WSLayout = () => {
  const { pathname } = useLocation();
  const { userData } = useAuth();

  const isSuperAdmin = userData?.role === ROLES.SUPER_ADMIN;
  const isManageWorkspacePage = pathname === '/ws/manageworkspace';

  const showBackButton = isSuperAdmin && !isManageWorkspacePage;
  const showWorkspaceNameandTabs = (isSuperAdmin && !isManageWorkspacePage) || !isSuperAdmin;

  return (
    <>
      <div className="ws-root">
        <Header
          hideHelp
          BackButton={showBackButton ? <BackButton /> : null}
          workspaceName={showWorkspaceNameandTabs ? userData?.workspaceName : ''}
        >
          <ProfileModal />
        </Header>
        {showWorkspaceNameandTabs && <WSTabs />}

        <div className="container-resource flex-grow-1">
          <EditorRootProvider imageUploadAPIService={uploadFile}>
            <Outlet />
          </EditorRootProvider>
        </div>
      </div>
    </>
  );
};

export default WSLayout;
