// CustomModal.js
import React from 'react';
import { CloseButton, Modal } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import './custom-modal.css'; // Add styles for the modal

// CustomModal Component (the container for the modal)
const CustomModal = ({
  children,
  className,
  size = 'md',
  show,
  centered = true,
  backdrop = 'static',
  keyboard = false,
  onHide,
}) => {
  if (!show) return null; // If the modal is not open, return null (do not render)

  return (
    <Modal
      className={`custom-modal ${className}`}
      show={show}
      centered={centered}
      backdrop={backdrop}
      keyboard={keyboard}
      size={size}
      onHide={onHide}
    >
      {children}
    </Modal>
  );
};

// CustomModalHeader Component
const CustomModalHeader = ({ children, className, onClose }) => {
  const intl = useIntl();

  return (
    <Modal.Header className={`custom-modal-header ${className}`}>
      <Modal.Title>{children}</Modal.Title>
      {onClose && (
        <CloseButton aria-label="Close" title={intl.formatMessage({ id: 'message.close' })} onClick={onClose} />
      )}
    </Modal.Header>
  );
};

// CustomModalBody Component
const CustomModalBody = ({ children, className }) => {
  return <Modal.Body className={`custom-modal-body ${className}`}>{children}</Modal.Body>;
};

// CustomModalFooter Component
const CustomModalFooter = ({ children, className }) => {
  return <Modal.Footer className={`custom-modal-footer ${className}`}>{children}</Modal.Footer>;
};

CustomModal.Header = CustomModalHeader;
CustomModal.Body = CustomModalBody;
CustomModal.Footer = CustomModalFooter;

export { CustomModal };
