import React, { useEffect, useState } from 'react';
import { ListGroup, Badge, Table, Spinner } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { CustomModal } from 'common/components/CustomModal';
import { BOOK_STATUS } from 'workspace/constants';
import { getBookHistory } from 'workspace/api/books.service';
import { toastify } from 'common/components/Toastify';

const bookStatusMap = {
  [BOOK_STATUS.NEW]: { badge: 'info', id: 'ws.status.new', defaultMessage: 'New' },
  [BOOK_STATUS.PUBLISHED]: { badge: 'success', id: 'ws.published', defaultMessage: 'Published' },
  [BOOK_STATUS.REPUBLISHED]: { badge: 'warning', id: 'ws.republished', defaultMessage: 'Republished' },
  [BOOK_STATUS.UNPUBLISHED]: { badge: 'secondary', id: 'ws.unpublished', defaultMessage: 'Unpublished' },
};

const getStatusBadge = status => {
  const { badge = 'light', id = 'ws.status.unknown', defaultMessage = 'Unknown Status' } = bookStatusMap[status] || {};

  return (
    <Badge bg={badge}>
      <FormattedMessage id={id} defaultMessage={defaultMessage} />
    </Badge>
  );
};

const LoadingRow = () => (
  <tr>
    <td colSpan="4" className="text-center">
      <Spinner />
    </td>
  </tr>
);

const NoHistoryRow = () => (
  <tr>
    <td colSpan="4" className="text-center">
      <FormattedMessage id="ws.noHistory" defaultMessage="No history available" />
    </td>
  </tr>
);

const HistoryRow = ({ record }) => (
  <tr>
    <td className="mw-200px text-truncate center-align">{record.bookName}</td>
    <td className="table-column-date center-align">
      {new Intl.DateTimeFormat('en-US').format(new Date(record.publishedDate))}
    </td>
    <td className="mw-100px text-truncate center-align">{record.publishedBy}</td>
    <td className="text-center">
      {record.disabled ? (
        <i className="bi bi-x status-x fs-3" title="InActive"></i>
      ) : (
        <i className="bi bi-check status-check fs-3" title="Active"></i>
      )}
    </td>
  </tr>
);

const getDisplayFields = book => {
  return [
    {
      labelId: 'ws.booktitle',
      defaultMessage: 'Book Title',
      value: book.title,
    },
    {
      labelId: 'ws.bookdescription',
      defaultMessage: 'Description',
      value: book.description,
    },
    {
      labelId: 'ws.author',
      defaultMessage: 'Author',
      value: book.authors
        .map(author => {
          const fullName = [author.firstName, author.middleName ? author.middleName : '', author.lastName].join(' ');
          return fullName;
        })
        .join(', '),
    },
    {
      labelId: 'ws.createdby',
      defaultMessage: 'Created By',
      value: book.creatorName,
    },
    {
      labelId: 'ws.createddate',
      defaultMessage: 'Created Date',
      value: new Date(book.created).toLocaleDateString(),
    },
    {
      labelId: 'ws.discipline',
      defaultMessage: 'Discipline',
      value: book.discipline,
    },
    {
      labelId: 'ws.publisher',
      defaultMessage: 'Publisher',
      value: book.publisher,
    },
    {
      labelId: 'ws.status',
      defaultMessage: 'Status',
      value: getStatusBadge(book.status),
    },
  ];
};

const BookDetailsModal = ({ book, handleClose }) => {
  const [history, setHistory] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (book) {
      const fetchHistory = async () => {
        try {
          setLoading(true);
          const historyData = await getBookHistory(book.guid);
          setHistory(historyData);
        } catch (error) {
          toastify.showErrorToast(error?.message);
        } finally {
          setLoading(false);
        }
      };

      fetchHistory();
    }
  }, [book]);

  if (!book) return null;

  return (
    <CustomModal show={true} size="lg" className="book-details-modal">
      <CustomModal.Header onClose={handleClose}>
        <FormattedMessage id="ws.bookdetails" defaultMessage="Book Details" />
      </CustomModal.Header>
      <CustomModal.Body>
        <ListGroup variant="flush">
          {getDisplayFields(book).map(field => (
            <ListGroup.Item>
              <strong>
                <FormattedMessage id={field.labelId} defaultMessage={field.defaultMessage} />:
              </strong>
              &nbsp;
              {field.value}
            </ListGroup.Item>
          ))}

          <ListGroup.Item>
            <strong>
              <FormattedMessage id="ws.bookhistory" defaultMessage="Book History" />:
            </strong>
            <div className="book-history-table-container">
              <Table className="book-history-table" striped bordered hover size="sm">
                <thead>
                  <tr>
                    <th>
                      <FormattedMessage id="ws.publishedBookID" defaultMessage="Published Book Name" />
                    </th>
                    <th className="table-column-date text-truncate">
                      <FormattedMessage id="ws.publishedDate" defaultMessage="Published Date" />
                    </th>
                    <th>
                      <FormattedMessage id="ws.publishedBy" defaultMessage="Published By" />
                    </th>
                    <th className="text-center">
                      <FormattedMessage id="ws.status" defaultMessage="Status" />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <LoadingRow />
                  ) : history.length ? (
                    history.map((record, index) => <HistoryRow key={index} record={record} />)
                  ) : (
                    <NoHistoryRow />
                  )}
                </tbody>
              </Table>
            </div>
          </ListGroup.Item>
        </ListGroup>
      </CustomModal.Body>
    </CustomModal>
  );
};

export default BookDetailsModal;
