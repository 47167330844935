import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { Dropdown, NavLink } from 'react-bootstrap';
import { subscribe, unsubscribe } from 'common/utils/events';
import { useAuth } from 'workspace/providers/auth/AuthContext';

const ProfileModal = () => {
  const { userData, logout } = useAuth();

  useEffect(() => {
    subscribe('ws:auth:logout', logout);

    return () => unsubscribe('ws:auth:logout', logout);
  }, []);

  const handleLogout = () => {
    logout();
  };

  return (
    <>
      <Dropdown id="nav-dropdown">
        <Dropdown.Toggle as={NavLink}>
          <div className="d-inline-flex flex-column justify-content-center align-items-center">
            <span
              className="d-inline-block text-truncate mw-200px"
              title={`${userData.firstName} ${userData.lastName}`}
            >
              <i className="bi bi-person-fill"></i> {userData.firstName} {userData.lastName}
            </span>
          </div>
        </Dropdown.Toggle>

        <Dropdown.Menu className="signout-menu" align="end">
          <Dropdown.Item as="button" onClick={handleLogout}>
            <FormattedMessage id="profile.signout" />
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
};

export default ProfileModal;
