import { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { Tree } from '@minoru/react-dnd-treeview';
import Placeholder from 'common/components/Placeholder';
import NoDragWrapper from 'common/components/NoDragWrapper';
import { useManageQuestionsContext } from 'workspace/providers/manage-questions/ManageQuestionsProvider';
import { getNodeIdsToClose } from 'workspace/providers/manage-questions/utils';
import DraggableIcon from 'common/components/DraggableIcon';
import { subscribe, unsubscribe } from 'common/utils/events';

const FoldersTreeView = ({ folderToEdit, onNodeEditClick, onFolderDeleteClick }) => {
  const intl = useIntl();
  const {
    treeDataAsArray,
    handleFolderDragAndDrop,
    handleQuestionDragAndDrop,
    treeData,
    selectedFolder,
    updateSelectedFolder,
    loadChildFoldersAndQuestions,
  } = useManageQuestionsContext();
  const treeRef = useRef(null);
  const dragSourceIdRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);

  useEffect(() => {
    subscribe('ws:import_folder_successful', collapseAllNodes);

    return () => unsubscribe('ws:import_folder_successful', collapseAllNodes);
  }, [treeData]);

  const collapseAllNodes = () => {
    treeRef.current.closeAll();
  };

  /**
   * Load child nodes for a given node
   *
   * @param {object} node - Node to load child nodes for
   */
  const loadChildNodes = node => {
    loadChildFoldersAndQuestions(node);
  };

  const handleDrop = async (newTree, { dropTarget }) => {
    if (dragSourceIdRef.current.isQuestion) {
      await handleQuestionDragAndDrop(newTree, dragSourceIdRef.current, dropTarget);
    } else {
      await handleFolderDragAndDrop(newTree, dragSourceIdRef.current, dropTarget);
      // closes the accordion of dragged folder and its children
      const draggedFolderWithChildIds = getNodeIdsToClose(treeData, dragSourceIdRef.current.id);
      treeRef.current.close(draggedFolderWithChildIds);
    }
  };

  /**
   * Handles the drag start event for a drag source.
   * @param {Object} dragSource - The drag source object that was dragged.
   */
  const handleDragStart = dragSource => {
    dragSourceIdRef.current = dragSource;
    setIsDragging(true);
  };

  const handleDragEnd = () => {
    setIsDragging(false);
  };

  const handleMouseDown = () => {
    setIsDragging(true);
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleDeleteClick = node => {
    onFolderDeleteClick(node);
  };

  const handleFolderSelection = node => {
    if (!node.isQuestion) {
      updateSelectedFolder(selectedFolder === node.id ? null : node.id);
    }
  };

  return (
    <div
      className={`folders-tree-view ${isDragging ? 'grabbing' : ''}`}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
    >
      <Tree
        ref={treeRef}
        sort={false}
        tree={treeDataAsArray}
        rootId={treeData?.guid}
        dropTargetOffset={5}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
        render={(node, { isOpen, onToggle, handleRef }) => (
          <div
            className={`ws-folders-tree-node ${selectedFolder === node.id ? 'clicked' : ''} ${node.isQuestion ? 'is-question' : ''}`}
          >
            <DraggableIcon ref={handleRef} />
            {!node.isQuestion && (
              <NoDragWrapper node={node}>
                {/* Container for both icons */}

                <span
                  className="caret-container"
                  onClick={() => {
                    if (!isOpen && !node.isChildrenLoaded) {
                      loadChildNodes(node);
                    }
                    onToggle();
                  }}
                  tabIndex="0"
                >
                  {isOpen ? <i className="fa fa-caret-down"></i> : <i className="fa fa-caret-right"></i>}
                </span>
              </NoDragWrapper>
            )}

            <div className="tree-node-text flex-grow-1" onClick={() => handleFolderSelection(node)}>
              {node.text}
            </div>
            <NoDragWrapper node={node}>
              <div className={`tree-node-action-buttons-container ${node.isQuestion ? 'is-question' : ''}`}>
                <button
                  className={`action-button ${folderToEdit?.id === node.id ? 'selected' : ''}`}
                  onClick={() => onNodeEditClick(node)}
                  title={intl.formatMessage({ id: 'message.edit', defaultMessage: 'Edit' })}
                >
                  <i className="bi bi-pencil-fill"></i>
                </button>
                {!node.isQuestion && (
                  <button
                    className="action-button"
                    onClick={() => handleDeleteClick(node)}
                    title={intl.formatMessage({ id: 'message.delete', defaultMessage: 'Delete' })}
                    aria-label="Delete"
                    disabled={folderToEdit?.id === node.id}
                  >
                    <i className="bi bi-trash"></i>
                  </button>
                )}
              </div>
            </NoDragWrapper>
          </div>
        )}
        dragPreviewRender={monitorProps => <div className="custom-drag-preview">{monitorProps.item.text}</div>}
        placeholderRender={(node, { depth }) => <Placeholder node={node} depth={depth} />}
        canDrop={(_currentTree, { dropTarget }) => {
          return dropTarget ? !dropTarget.isQuestion : true;
        }}
        onDrop={handleDrop}
      />
    </div>
  );
};

export default FoldersTreeView;
