import axios from './axios';

/**
 * Logs in a user with the provided username and password.
 *
 * @param {string} username - The username to log in with.
 * @param {string} password - The password to log in with.
 * @returns {Promise<object>} - A promise that resolves with the login response data.
 */
export async function login(username, password) {
  return axios.post('users/login', {
    username,
    password,
  });
}

/**
 * Refreshes the authentication token by making a POST request to the server.
 *
 * @param {Object} payload - The payload containing the necessary data to refresh the token.
 * @returns {Promise} A promise that resolves to the response of the POST request.
 */
export async function refreshAuthToken(payload) {
  return axios.post('/users/refresh-token', payload);
}
