import React, { useState, useEffect } from 'react';
import { Button, Row, Col } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import BookCard from './BookCard';
import CreateOrEditBookModal from './CreateOrEditBookModal';
import BookDetailsModal from './BookDetailsModal';
import SearchBox from 'common/components/SearchBox/SearchBox';
import { useManageQuestionsContext } from 'workspace/providers/manage-questions/ManageQuestionsProvider';
import { toastify } from 'common/components/Toastify';
import { BOOK_STATUS } from 'workspace/constants';
import { deleteBook, getBooks, publishBook, republishBook, unpublishBook } from 'workspace/api/books.service';
import { useLoader } from 'common/providers/LoaderProvider';
import BookListModal from './BookListModal';
import { useAuth } from 'workspace/providers/auth/AuthContext';

const BooksList = () => {
  const intl = useIntl();
  const { showLoader, hideLoader } = useLoader();
  const { updateSelectedBook } = useManageQuestionsContext();
  const { userData } = useAuth();
  const [books, setBooks] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(true);

  const [activeBook, setActiveBook] = useState(null);
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [showCreateOrEditBookModal, setShowCreateOrEditBookModal] = useState(false);
  const [showBookListModal, setshowBookListModal] = useState(false);
  const [activeList, setActiveList] = useState(null);

  useEffect(() => {
    fetchBooks();
  }, []);

  const fetchBooks = async () => {
    try {
      showLoader();
      const booksData = await getBooks(true, userData.workspaceId);
      setBooks(booksData || []);
      setLoading(true);
    } catch (err) {
      if (err.status === 404) {
        setBooks([]);
        return;
      } else {
        toastify.showErrorToast(intl.formatMessage({ id: 'ws.unabletofetchbook' }));
      }
    } finally {
      hideLoader();
      setLoading(false);
    }
  };

  const handleActions = (action, book, status) => {
    switch (action) {
      case 'details':
        setActiveBook(book);
        setShowDetailsModal(true);
        break;
      case 'edit':
        setActiveBook(book);
        setShowCreateOrEditBookModal(true);
        break;
      case 'delete':
        handleDeleteBook(book);
        break;
      case 'open':
        updateSelectedBook(book);
        break;
      case BOOK_STATUS.PUBLISHED:
        handlePublishBook(book);
        break;
      case BOOK_STATUS.REPUBLISHED:
        handleRePublishBook(book);
        break;
      case BOOK_STATUS.UNPUBLISHED:
        //updateBookStatus(book, status);
        handleUnPublishBook(book);
        break;
      default:
        console.error(`Unknown action: ${action}`);
    }
  };

  const handleDeleteBook = async book => {
    try {
      await deleteBook(book.guid);
      toastify.showSuccessToast(intl.formatMessage({ id: 'success.bookdelete' }));
      showLoader();
      fetchBooks();
    } catch (error) {
      toastify.showErrorToast(error?.message);
    } finally {
      hideLoader();
    }
  };

  const handlePublishBook = async book => {
    try {
      showLoader();
      await publishBook(book.guid);
      toastify.showSuccessToast(intl.formatMessage({ id: 'success.book.publish' }));
      fetchBooks();
    } catch (error) {
      toastify.showErrorToast(error?.message);
    } finally {
      hideLoader();
    }
  };

  const handleRePublishBook = async book => {
    try {
      showLoader();
      await republishBook(book.guid);
      toastify.showSuccessToast(intl.formatMessage({ id: 'success.book.republish' }));
      fetchBooks();
    } catch (error) {
      toastify.showErrorToast(error?.message);
    } finally {
      hideLoader();
    }
  };

  const handleUnPublishBook = async book => {
    try {
      showLoader();
      await unpublishBook(book.guid);
      toastify.showSuccessToast(intl.formatMessage({ id: 'success.book.unpublish' }));
      fetchBooks();
    } catch (error) {
      toastify.showErrorToast(error?.message);
    } finally {
      hideLoader();
    }
  };

  const handleCreateBookClick = () => {
    setShowCreateOrEditBookModal(true);
    setActiveBook(null);
  };

  const handleCreateListClick = () => {
    setshowBookListModal(true);
    setActiveList(null);
  };

  const handleClose = () => {
    setActiveBook(null);
    setActiveList(null);
    setShowDetailsModal(false);
    setShowCreateOrEditBookModal(false);
    setshowBookListModal(false);
  };

  const handleSearchChange = value => {
    setSearchTerm(value); // Update search term in parent component
  };

  const updateBookStatus = (book, newStatus) => {
    setBooks(prevBooks => {
      return prevBooks.map(prevBook => (prevBook.guid === book.guid ? { ...prevBook, status: newStatus } : prevBook));
    });
  };

  // Filter books based on search term
  const filteredBooks = books.filter(book =>
    `${book.creatorName} ${book.title} ${book.discipline}`.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <>
      <Row>
        <Col xs={12} className="books-list-actions-container">
          <div className="books-list-actions-left">
            <SearchBox searchText={searchTerm} placeholder="Search Books" onSearch={handleSearchChange} />
          </div>
          <div className="books-list-actions-right">
            <Button variant="primary" className="book-card-buttons" size="sm" onClick={handleCreateListClick}>
              <FormattedMessage id="ws.addbook" />
            </Button>
            <Button variant="primary" className="book-card-buttons" size="sm" onClick={handleCreateBookClick}>
              <FormattedMessage id="ws.createbook" />
            </Button>
          </div>
        </Col>
      </Row>
      <Row className="books-list-grid">
        {!loading && books.length === 0 && (
          <div className="no-matching-books d-flex justify-content-center align-items-center" tabIndex="0">
            <FormattedMessage id="ws.noBooksMessage" defaultMessage="No books found" />
          </div>
        )}
        {filteredBooks.map((book, index) => (
          <Col key={book.guid} className="book-custom-col">
            <BookCard book={book} handleActions={handleActions} />
          </Col>
        ))}
        {filteredBooks.length === 0 && books.length != 0 && (
          <div className="no-matching-books d-flex justify-content-center align-items-center" tabIndex="0">
            <FormattedMessage id="noMatchingBooksMessage" defaultMessage="No matching books found" />
          </div>
        )}
      </Row>
      {showCreateOrEditBookModal && (
        <CreateOrEditBookModal
          activeBook={activeBook} // Pass the book to be edited
          reload={fetchBooks}
          handleClose={handleClose}
        />
      )}
      {showBookListModal && <BookListModal activeList={activeList} reload={fetchBooks} handleClose={handleClose} />}
      {showDetailsModal && activeBook && <BookDetailsModal book={activeBook} handleClose={handleClose} />}
    </>
  );
};

export default BooksList;
