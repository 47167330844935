import BooksList from 'workspace/components/ManageBooksTab/BooksList';
import ManageQuestionsTab from '../ManageQuestions/ManageQuestionsTab';
import { useManageQuestionsContext } from 'workspace/providers/manage-questions/ManageQuestionsProvider';
import './manage-books-tab.css';
import { useEffect } from 'react';

const ManageBooksTab = () => {
  const { selectedBook, clearManageQuestionsState } = useManageQuestionsContext();

  // clears the manage questions state when the user navigates away from the manage books tab
  useEffect(() => {
    return () => clearManageQuestionsState();
  }, []);

  return selectedBook ? (
    <ManageQuestionsTab />
  ) : (
    <div className="manage-books-tab-container">
      <BooksList />
    </div>
  );
};

export default ManageBooksTab;
