import $ from 'jquery';
import { v4 as uuidv4 } from 'uuid';
import DOMPurify from 'dompurify';
import { decodeSpecialCharacters, deepClone } from '.';
import { appendHTMLNodes, appendNodes, getSerializedXML } from './qti-helpers';
import { ORIENTATION, QUESTION_TYPES } from 'common/constants/question-constants';

var QtiService = {};

const addSpaceBeforeAndAfterButton = html => {
  return html.replace(/\s*(<button)/g, ' $1').replace(/(\<\/button>)\s*/g, '$1 ');
};

QtiService.getQtiModel = function (qtiXML, quizType) {
  var qtiModel = QtiService.getQtiJsonModal(qtiXML, quizType);
  return qtiModel;
};

QtiService.getQtiJsonModal = function (qtiXML, quizType) {
  const xml = $.parseXML(qtiXML);
  const qtiModel = {};

  qtiModel.Caption = DOMPurify.sanitize(QtiService.getQuestionCaption(xml));

  switch (quizType) {
    case 'MultipleChoice':
    case 'MultipleResponse':
    case 'TrueFalse':
      qtiModel.Options = QtiService.getQuestionOptions(xml, quizType);
      qtiModel.CorrectAnswer = QtiService.getQuestionCorrectAnswers(xml, quizType);
      qtiModel.orientation = QtiService.getQuestionOrientation(xml);

      break;

    case 'Essay':
      qtiModel.Caption = DOMPurify.sanitize(QtiService.getEssayCaption(xml));
      qtiModel.RecommendedAnswer = QtiService.getEssayRecommendedAnswer(xml);
      qtiModel.expectedLines = QtiService.getEssayExpectedLines(xml);

      break;

    case 'Matching':
      qtiModel.Options = QtiService.getQuestionOptions(xml, quizType);

      break;

    case 'FillInBlanks': {
      const captionsArray = QtiService.getFIBBQuestionCaptionsArray(xml);
      qtiModel.Caption = DOMPurify.sanitize(QtiService.getFbCaptionHTML(captionsArray));
      qtiModel.Blanks = QtiService.getFIBBlanks(xml, qtiModel.Caption);

      break;
    }
  }
  return qtiModel;
};

QtiService.getFIBBlanks = (xml, html) => {
  const answers = $(xml).find('responseDeclaration');
  const blanks = $(xml).find('itemBody').find('textEntryInteraction');
  const buttons = $('<p></p>').append(html).find('button');

  const result = [];

  answers.each(function (i, element) {
    if (element.childNodes.length > 0) {
      const answer = element.childNodes[0].childNodes[0].attributes['mapKey'].nodeValue;
      const blankSpace = QtiService.updateBlankSize(parseInt($(blanks[i]).attr('expectedLength')) || 20);
      const key = $(buttons[i]).attr('data-key');
      result.push({ answer, blankSpace, key, name: `RESPONSE_${i + 1}` });
    }
  });

  return result;
};

QtiService.updateBlankSize = function (size) {
  switch (size) {
    case 25:
    case 50:
    case 100:
      return 20;
    case 150:
      return 40;
    case 200:
    case 325:
      return 80;
    case 450:
      return 110;
  }
  return size;
};

QtiService.getFIBBQuestionCaptionsArray = function (xml) {
  var caption = [];
  var txtContent;
  var item;

  if ($(xml).find('itemBody').find('blockquote').find('p').eq(0)[0].childNodes.length > 0) {
    $($(xml).find('itemBody').find('blockquote').find('p').eq(0)[0].childNodes).each(function (node) {
      txtContent = '';
      item = '';
      if ($(this)[0].nodeType == 4) {
        item = { type: 1, content: $(this)[0].textContent };
        caption.push(item);
      } else if ($(this)[0].nodeName == 'textEntryInteraction') {
        item = { type: 2, content: txtContent };
        caption.push(item);
      }
    });
  } else {
    item = { type: 1, content: '' };
    caption.push(item);
  }

  return caption;
};

// to render Caption of Fill in the blank which contains html content
QtiService.getFbCaptionHTML = function (captions) {
  let caption = '';
  let count = 0;

  $.each(captions, function (index, captionElement) {
    if (captionElement.type == 1) {
      caption += captionElement.content;
    } else {
      const key = uuidv4();
      const blankHTML = `<button data-key="${key}">Fill Blank</button>`;

      caption += blankHTML;
    }
  });

  if (caption.length && !(caption.startsWith('<p>') || caption.startsWith('<div>'))) {
    caption = `<div>${caption}</div>`;
  }

  caption = addSpaceBeforeAndAfterButton(caption);

  return caption;
};

QtiService.getQuestionCaption = function (xml) {
  return QtiService.getSerializedXML($(xml).find('itemBody').find('p').eq(0));
};

QtiService.getEssayExpectedLines = function (xml) {
  var nodeEssayPageSize = '0';
  if ($(xml).find('itemBody').find('extendedTextInteraction').length > 0)
    nodeEssayPageSize = $(xml).find('itemBody').find('extendedTextInteraction').eq(0).attr('expectedLines');

  return nodeEssayPageSize;
};

QtiService.getEssayCaption = function (xml) {
  return QtiService.getSerializedXML($(xml).find('itemBody').find('blockquote').find('p').eq(0));
};

QtiService.getEssayRecommendedAnswer = function (xml) {
  var recommendedAnswer = '';
  $(xml)
    .find('responseDeclaration')
    .each(function (i, e) {
      var valueElement = $(e).find('correctResponse value');
      if (recommendedAnswer == '') {
        recommendedAnswer = valueElement.text();
      } else {
        recommendedAnswer = recommendedAnswer + ' ' + valueElement.text();
      }
    });
  return recommendedAnswer;
};

QtiService.jsonReplaceUL = function (content) {
  var htmlText = content.trim().replace(/&nbsp;/, ' ');
  var element = $('<p></p>');
  $(element).append(htmlText);

  element.find('img').each(function (i, obj) {
    var srcUri = $(this).attr('src');
    var slashAarray = srcUri.split('/');
    var file = slashAarray[slashAarray.length - 1];
    var imageAarray = file.split('?');
    $(obj).replaceWith($('<u contenteditable="false" src="' + srcUri + '">' + imageAarray[0] + '</u>'));
  });

  return element[0].innerHTML;
};

QtiService.getQuestionOptions = function (xml, quizType) {
  var optionList = [];
  switch (quizType) {
    case 'MultipleChoice':
    case 'MultipleResponse':
    case 'TrueFalse':
      optionList = QtiService.getSimpleChoices(xml, quizType);
      break;
    case 'Essay':
      break;
    case 'FillInBlanks':
      break;
    case 'Matching':
      optionList = QtiService.getMacthingOptions(xml);
      break;
  }
  return optionList;
};

QtiService.getInlineChoice = function (qtiXML) {
  var rightColumnOptions = [];
  $(qtiXML)
    .find('itemBody')
    .find('blockquote')
    .each(function (i, e) {
      var leftOptionIdentifier = $(this).find('inlineChoiceInteraction').attr('responseIdentifier');
      $(qtiXML)
        .find('responseDeclaration')
        .each(function (i, e) {
          var leftIdentifier = $(this).attr('identifier');
          if (leftOptionIdentifier == leftIdentifier) {
            var rightOptionIdentifier = $(this).find('mapEntry').attr('mapKey');
            $(qtiXML)
              .find('itemBody')
              .find('blockquote')
              .eq(0)
              .find('inlineChoiceInteraction inlineChoice')
              .each(function (i, e) {
                var rightIdentifier = $(this).attr('identifier');
                if (rightOptionIdentifier == rightIdentifier) {
                  rightColumnOptions.push($(this).text());
                }
              });
          }
        });
    });
  return rightColumnOptions;
};

QtiService.getInlineChoiceInteraction = function (qtiXML) {
  var leftColumnOptions = [];
  $(qtiXML)
    .find('itemBody')
    .find('blockquote')
    .each(function (i, e) {
      $(this).find('p').find('inlineChoiceInteraction').remove();
      leftColumnOptions.push(QtiService.getSerializedXML($(this).find('p').eq(0)));
    });
  return leftColumnOptions;
};

QtiService.getMacthingOptions = function (qtiXML) {
  var rightColumnOptions = QtiService.getInlineChoice(qtiXML);
  var leftColumnOptions = QtiService.getInlineChoiceInteraction(qtiXML);
  var optionList = [];
  for (var i in leftColumnOptions) {
    for (var j in rightColumnOptions) {
      if (i == j) {
        var matchingOptions = { option: leftColumnOptions[i].trim(), matchingOption: rightColumnOptions[j].trim() };
        optionList.push(matchingOptions);
        break;
      }
    }
  }
  return optionList;
};

QtiService.getQuestionOrientation = function (qtiXML) {
  return $(qtiXML).find('itemBody').find('choiceInteraction').attr('orientation') || ORIENTATION.VERTICAL;
};

QtiService.getQuestionCorrectAnswers = function (qtiXML, quizType) {
  var correctAnswerList = [];
  switch (quizType) {
    case 'MultipleChoice':
    case 'TrueFalse':
      correctAnswerList = QtiService.getMultipleChoiceCorrectAnswer(qtiXML);
      break;
    case 'MultipleResponse':
      correctAnswerList = QtiService.getMultipleResponseCorrectAnswer(qtiXML);
      break;
    case 'Essay':
      break;
    case 'FillInBlanks':
      break;
    case 'Matching':
      break;
  }
  return correctAnswerList;
};

QtiService.getSimpleChoices = function (qtiXML, type) {
  const optionList = [];
  $(qtiXML)
    .find('itemBody')
    .find('choiceInteraction')
    .find('simpleChoice')
    .each(function () {
      const value = QtiService.getSerializedXML($(this));
      optionList.push(type === 'TrueFalse' ? decodeSpecialCharacters(value) : value);
    });
  return optionList;
};

QtiService.getMultipleChoiceCorrectAnswer = function (qtiXML) {
  var correctAnswerIndex;
  $(qtiXML)
    .find('setOutcomeValue[identifier="SCORE"] baseValue')
    .each(function (i, e) {
      if (parseFloat($(this).text()) > 0) {
        correctAnswerIndex = i;
      }
    });
  var responseIndex = correctAnswerIndex + 1;
  correctAnswerIndex = $(qtiXML)
    .find('itemBody')
    .find('choiceInteraction')
    .find("simpleChoice[identifier='RESPONSE_" + responseIndex + "']")
    .index();

  return correctAnswerIndex;
};

QtiService.getMultipleResponseCorrectAnswer = function (qtiXML) {
  var correctAnswerList = [];
  var responseAnswerList = [];
  $(qtiXML)
    .find('responseDeclaration mapEntry')
    .each(function (i, e) {
      responseAnswerList.push(false);
      if (parseFloat($(this).attr('mappedValue')) > 0) {
        correctAnswerList.push(true);
      } else {
        correctAnswerList.push(false);
      }
    });

  $.each(correctAnswerList, function (index, item) {
    if (item) {
      var responseIndex = $(qtiXML)
        .find('itemBody')
        .find('choiceInteraction')
        .find("simpleChoice[identifier='RESPONSE_" + (index + 1) + "']")
        .index();
      responseAnswerList[responseIndex] = true;
    }
  });

  return responseAnswerList;
};

QtiService.getSerializedXML = function (qtiNode) {
  var serializedQtiNode = '';
  var serializedText = '';
  var xmlChildren = qtiNode.eq(0).get(0).childNodes;
  for (let i = 0; i < xmlChildren.length; i++) {
    if (xmlChildren[i].nodeType == 4) {
      //Purpose: Image getting resized on editing.
      //Putting the ctext content inside the span element and findout
      //the images.
      //Looping through the images and find out the css class.
      //If there is no css class apply css class and remove the height and width.
      var textContent = $('<span>' + xmlChildren[i].textContent + '</span>');
      var images = textContent.find('img');
      if (images.length > 0) {
        for (let j = 0; j < images.length; j++) {
          if (
            textContent.find('img').eq(j).attr('class') == null ||
            textContent.find('img').eq(j).attr('class') != 'qtiQuestionImage'
          ) {
            textContent.find('img').eq(j).attr('class', 'qtiQuestionImage');
            textContent.find('img').eq(j).removeAttr('height');
            textContent.find('img').eq(j).removeAttr('width');
          }
        }
        serializedQtiNode = textContent.html();
      } else {
        serializedQtiNode = xmlChildren[i].textContent;
      }
    } else {
      serializedQtiNode = new XMLSerializer().serializeToString(xmlChildren[i]);
    }
    serializedText = serializedText + serializedQtiNode;
  }
  return serializedText;
};

const printOptionMapping = {
  [QUESTION_TYPES.MultipleChoice]: 'Answer Choice',
  [QUESTION_TYPES.TrueFalse]: 'True',
  [QUESTION_TYPES.MultipleResponse]: 'Answer Choice',
  [QUESTION_TYPES.Matching]: 'Option',
  [QUESTION_TYPES.FillInBlanks]: 'Answer Choice',
};

var replaceImageFromJsonContent = function (content) {
  var htmlText = content.replace(/&nbsp;/, ' ');
  var element = $('<p></p>');
  $(element).append(htmlText);

  var anchorTags = element.find('u[contenteditable]');
  anchorTags.each(function () {
    var url = $(this).attr('url');
    htmlText = htmlText.replace($(this).get(0).outerHTML, '<img class="qtiQuestionImage" src="' + url + '"></img>');
  });
  return htmlText;
};

var buildQuestionOptionTag = function (xml, node) {
  const {
    quizType,
    qtiModel: { orientation, Options },
  } = node;
  $(xml).find('itemBody').find('choiceInteraction').attr('orientation', orientation);

  $(xml).find('itemBody').find('choiceInteraction').find('simpleChoice').remove();
  let optionText = '';
  const optionTag = '<simpleChoice identifier="@RESPONSE" fixed="false">@val</simpleChoice>';

  $.each(Options, function (index, Option) {
    optionText = replaceImageFromJsonContent(Option);
    Options[index] = optionText;
    if (optionText.startsWith('<p>')) {
      optionText = optionText.substring(3, optionText.length - 4);
    }
    optionText = optionText || printOptionMapping[quizType];
    let optionTagAppend = optionTag.replace('@RESPONSE', 'RESPONSE_' + (index + 1));
    optionTagAppend = optionTagAppend.replace('@val', '<![CDATA[' + optionText + ']]>');
    const item = $.parseXML(optionTagAppend);
    $(xml).find('itemBody').find('choiceInteraction').append($(item).children(0));
  });
};

var appendResponseProcessingTag = function (xml, htmlOptionsCnt) {
  $(xml).find('responseCondition').children().slice(3).remove();

  var $responseElseIf =
    '<responseElseIf><match><variable identifier="RESPONSE"/><baseValue baseType="identifier">RESPONSE_1</baseValue></match><setOutcomeValue identifier="SCORE"><baseValue baseType="float">0</baseValue></setOutcomeValue><setOutcomeValue identifier="FEEDBACK"><baseValue baseType="identifier">FEEDBACK_1</baseValue></setOutcomeValue></responseElseIf>';
  for (var i = 3; i < htmlOptionsCnt; i++) {
    var item = $.parseXML($responseElseIf.replace('RESPONSE_1', 'RESPONSE_' + (i + 1)));

    $(xml).find('responseCondition').append(item.childNodes[0]);
  }
};

var appendMultipleResponseProcessingTag = function (xml, htmlOptionsCnt) {
  $(xml).find('responseCondition').children().slice(3).remove();

  var $responseElseIf =
    '<responseIf><member><variable identifier="RESPONSE"/><baseValue baseType="identifier">RESPONSE_1</baseValue></member><setOutcomeValue identifier="FEEDBACK"><baseValue baseType="identifier">FEEDBACK_1</baseValue></setOutcomeValue></responseIf>';
  for (var i = 3; i < htmlOptionsCnt; i++) {
    var item = $.parseXML($responseElseIf.replace('RESPONSE_1', 'RESPONSE_' + (i + 1)));

    $(xml).find('responseCondition').append(item.childNodes[0]);
  }
};

var updateMapEntryTag = function (xml, node) {
  var $mapEntry = '<mapEntry mapKey="RESPONSE_1" mappedValue="0" />';
  $(xml).find('responseDeclaration mapping').children().slice(3).remove();
  for (var i = 3; i < node.qtiModel.Options.length; i++) {
    var item = $.parseXML($mapEntry.replace('RESPONSE_1', 'RESPONSE_' + (i + 1)));

    $(xml).find('responseDeclaration mapping').append(item.childNodes[0]);
  }

  $(xml).find('responseDeclaration mapEntry').attr('mappedValue', '0');

  for (var index = 0; index < node.qtiModel.CorrectAnswer.length; index++) {
    if (node.qtiModel.CorrectAnswer[index]) {
      $(xml).find('responseDeclaration mapEntry').eq(index).attr('mappedValue', '1');
    }
  }
};

var setIdentifierScore = function (xml, node) {
  $(xml).find('setOutcomeValue[identifier="SCORE"] baseValue').text('0');
  $(xml).find('setOutcomeValue[identifier="SCORE"] baseValue').eq(node.qtiModel.CorrectAnswer).text('1');
};

const replaceFIBblank = function (elm, text, blanks) {
  const buttons = elm.find('button');
  let textEntryBackUp;
  let actualContent;
  for (let i = 0; i < buttons.length; i++) {
    if (actualContent == null)
      actualContent = `<![CDATA[${text.substring(0, text.indexOf(buttons.get(i).outerHTML))}]]>`;
    else {
      const index = text.indexOf(buttons.get(i - 1).outerHTML) + buttons.get(i - 1).outerHTML.length;
      actualContent = actualContent + `<![CDATA[${text.substring(index, text.indexOf(buttons.get(i).outerHTML))}]]>`;
    }
    textEntryBackUp = `<textEntryInteraction expectedLength="${blanks[i].blankSpace}" responseIdentifier="${blanks[i].name}" />`;
    actualContent = actualContent + textEntryBackUp;
  }
  const index =
    text.indexOf(buttons.get(buttons.length - 1).outerHTML) + buttons.get(buttons.length - 1).outerHTML.length;
  actualContent = actualContent + `<![CDATA[${text.substring(index, text.length)}]]>`;

  return actualContent;
};

QtiService.getQtiXML = function (data) {
  const node = deepClone(data);
  const xml = $.parseXML(node.qtixml);
  const quizType = node.quizType;
  node.qtiModel.Caption = replaceImageFromJsonContent(node.qtiModel.Caption);

  let qstnCaption = node.qtiModel.Caption;

  appendNodes($(xml).find('itemBody').find('p').eq(0), '<![CDATA[' + qstnCaption + ']]>');

  $(xml).find('assessmentItem').attr('identifier', 'QUESTION-X');

  switch (quizType) {
    case 'MultipleChoice':
      buildQuestionOptionTag(xml, node);

      appendResponseProcessingTag(xml, node.qtiModel.Options.length);

      setIdentifierScore(xml, node);

      break;

    case 'MultipleResponse':
      buildQuestionOptionTag(xml, node);

      appendMultipleResponseProcessingTag(xml, node.qtiModel.Options.length);

      updateMapEntryTag(xml, node);

      break;

    case 'TrueFalse':
      buildQuestionOptionTag(xml, node);
      setIdentifierScore(xml, node);
      break;

    case 'Essay':
      $(xml).find('itemBody').find('extendedTextInteraction').eq(0).attr('expectedLines', node.qtiModel.expectedLines);
      appendNodes(
        $(xml).find('responseDeclaration').find('correctResponse').find('value').eq(0),
        '<![CDATA[' + node.qtiModel.RecommendedAnswer + ']]>'
      );
      break;

    case 'Matching':
      var responseDeclaration = $(xml).find('responseDeclaration');
      var responseTag =
        ' <responseDeclaration identifier="@RESPONSE" cardinality="single" baseType="identifier">' +
        '<mapping defaultValue="0"><mapEntry mapKey="@RESP" mappedValue="1"/></mapping></responseDeclaration>';

      for (var i = responseDeclaration.length; i < node.qtiModel.Options.length; i++) {
        responseTag = responseTag.replace('@RESPONSE', 'RESPONSE_' + (i + 1));
        responseTag = responseTag.replace('@RESP', 'RESP_' + (i + 1));
        var item = $.parseXML(responseTag);
        $(xml).find('responseDeclaration:last').after($(item).children(0));
      }

      $(xml).find('itemBody').find('blockquote').remove();
      var optionText = '';
      var optionTag =
        '<blockquote><p>@p<inlineChoiceInteraction responseIdentifier="@RESPONSE" shuffle="true">' +
        '</inlineChoiceInteraction></p></blockquote>';

      var inlineChoiceTags =
        '<inlineChoiceInteraction responseIdentifier="@RESPONSE" shuffle="true"></inlineChoiceInteraction>';
      var inlineChoiceTag = '<inlineChoice identifier="@RESP">@RESP_Val</inlineChoice>';

      var xmlDoc = $.parseXML(inlineChoiceTags);
      inlineChoiceTags = $(xmlDoc);

      for (var i = 0; i < node.qtiModel.Options.length; i++) {
        optionText = replaceImageFromJsonContent(node.qtiModel.Options[i].matchingOption);
        node.qtiModel.Options[i].matchingOption = optionText;
        if (optionText.startsWith('<p>')) {
          optionText = optionText.substring(3, optionText.length - 4);
        }
        optionText = optionText || 'Match';

        var optionTagAppend = inlineChoiceTag.replace('@RESP', 'RESP_' + (i + 1));
        optionTagAppend = optionTagAppend.replace('@RESP_Val', '<![CDATA[' + optionText + ']]>');

        var item = $.parseXML(optionTagAppend);

        inlineChoiceTags.find('inlineChoiceInteraction').append($(item).children(0));
      }

      for (var i = 0; i < node.qtiModel.Options.length; i++) {
        optionText = replaceImageFromJsonContent(node.qtiModel.Options[i].option);
        node.qtiModel.Options[i].option = optionText;
        if (optionText.startsWith('<p>')) {
          optionText = optionText.substring(3, optionText.length - 4);
        }
        optionText = optionText || printOptionMapping[node.quizType];
        var optionTagAppend = optionTag.replace('@RESPONSE', 'RESPONSE_' + (i + 1));
        optionTagAppend = optionTagAppend.replace('@p', '<![CDATA[' + optionText + ']]>');

        var item = $.parseXML(optionTagAppend);

        appendHTMLNodes(
          $(item).find('inlineChoiceInteraction'),
          getSerializedXML(inlineChoiceTags.find('inlineChoiceInteraction'))
        );

        $(xml).find('itemBody').append($(item).children(0));
      }

      break;
    case 'FillInBlanks': {
      if ($(node.qtiModel.Caption).find('button').length > 0) {
        qstnCaption = replaceFIBblank($(node.qtiModel.Caption), qstnCaption, node.qtiModel.Blanks);
        $(xml).find('itemBody').find('p').eq(0).empty();
        appendHTMLNodes($(xml).find('itemBody').find('p').eq(0), qstnCaption);
      } else {
        appendNodes($(xml).find('itemBody').find('p').eq(0), `<![CDATA[${qstnCaption}]]>`);
      }

      const responseDeclaration = $(xml).find('responseDeclaration');
      responseDeclaration.remove();

      const responseTagTemplate =
        '<responseDeclaration identifier="@RESPONSE" cardinality="single" baseType="string">' +
        '<mapping defaultValue="0"><mapEntry mapKey="@RESP" mappedValue="1" caseSensitive="false"/></mapping></responseDeclaration>';

      node.qtiModel.Blanks.forEach((blank, index) => {
        const answer = encodeSpecialCharText(blank.answer);
        const responseTag = responseTagTemplate.replace('@RESPONSE', blank.name).replace('@RESP', answer);

        const item = $.parseXML(responseTag);

        if (index == 0) $(xml).find('assessmentItem').prepend($(item).children(0));
        else
          $(xml)
            .find('responseDeclaration')
            .eq(index - 1)
            .after($(item).children(0));
      });

      break;
    }
  }

  var serializer = new XMLSerializer();
  var editedXML = serializer.serializeToString(xml);
  editedXML = editedXML.replaceAll('xmlns=""', '');

  return editedXML;
};

var encodeSpecialCharText = function (text) {
  /*text = text.replace(/"/g,'&quot;');
        text = text.replace(/'/g,'&apos;');
        text = text.replace(/'/g,'&apos;');
        var chars = ["©","Û","®","ž","Ü","Ÿ","Ý","$","Þ","%","¡","ß","¢","à","£","á","À","¤","â","Á","¥","ã","Â","¦","ä","Ã","§","å","Ä","¨","æ","Å","©","ç","Æ","ª","è","Ç","«","é","È","¬","ê","É","­","ë","Ê","®","ì","Ë","¯","í","Ì","°","î","Í","±","ï","Î","²","ð","Ï","³","ñ","Ð","´","ò","Ñ","µ","ó","Õ","¶","ô","Ö","·","õ","Ø","¸","ö","Ù","¹","÷","Ú","º","ø","Û","»","ù","Ü","@","¼","ú","Ý","½","û","Þ","€","¾","ü","ß","¿","ý","à","‚","À","þ","á","ƒ","Á","ÿ","å","„","Â","æ","…","Ã","ç","†","Ä","è","‡","Å","é","ˆ","Æ","ê","‰","Ç","ë","Š","È","ì","‹","É","í","Œ","Ê","î","Ë","ï","Ž","Ì","ð","Í","ñ","Î","ò","‘","Ï","ó","’","Ð","ô","“","Ñ","õ","”","Ò","ö","•","Ó","ø","–","Ô","ù","—","Õ","ú","˜","Ö","û","™","×","ý","š","Ø","þ","›","Ù","ÿ","œ","Ú"];
        var codes = ["&copy;","&#219;","&reg;","&#158;","&#220;","&#159;","&#221;","&#36;","&#222;","&#37;","&#161;","&#223;","&#162;","&#224;","&#163;","&#225;","&Agrave;","&#164;","&#226;","&Aacute;","&#165;","&#227;","&Acirc;","&#166;","&#228;","&Atilde;","&#167;","&#229;","&Auml;","&#168;","&#230;","&Aring;","&#169;","&#231;","&AElig;","&#170;","&#232;","&Ccedil;","&#171;","&#233;","&Egrave;","&#172;","&#234;","&Eacute;","&#173;","&#235;","&Ecirc;","&#174;","&#236;","&Euml;","&#175;","&#237;","&Igrave;","&#176;","&#238;","&Iacute;","&#177;","&#239;","&Icirc;","&#178;","&#240;","&Iuml;","&#179;","&#241;","&ETH;","&#180;","&#242;","&Ntilde;","&#181;","&#243;","&Otilde;","&#182;","&#244;","&Ouml;","&#183;","&#245;","&Oslash;","&#184;","&#246;","&Ugrave;","&#185;","&#247;","&Uacute;","&#186;","&#248;","&Ucirc;","&#187;","&#249;","&Uuml;","&#64;","&#188;","&#250;","&Yacute;","&#189;","&#251;","&THORN;","&#128;","&#190;","&#252","&szlig;","&#191;","&#253;","&agrave;","&#130;","&#192;","&#254;","&aacute;","&#131;","&#193;","&#255;","&aring;","&#132;","&#194;","&aelig;","&#133;","&#195;","&ccedil;","&#134;","&#196;","&egrave;","&#135;","&#197;","&eacute;","&#136;","&#198;","&ecirc;","&#137;","&#199;","&euml;","&#138;","&#200;","&igrave;","&#139;","&#201;","&iacute;","&#140;","&#202;","&icirc;","&#203;","&iuml;","&#142;","&#204;","&eth;","&#205;","&ntilde;","&#206;","&ograve;","&#145;","&#207;","&oacute;","&#146;","&#208;","&ocirc;","&#147;","&#209;","&otilde;","&#148;","&#210;","&ouml;","&#149;","&#211;","&oslash;","&#150;","&#212;","&ugrave;","&#151;","&#213;","&uacute;","&#152;","&#214;","&ucirc;","&#153;","&#215;","&yacute;","&#154;","&#216;","&thorn;","&#155;","&#217;","&yuml;","&#156;","&#218;"];*/
  var chars = [/\"/g, /\'/g];
  var codes = ['&quot;', '&apos;'];

  for (var x = 0; x < chars.length; x++) {
    text = text.replace(chars[x], codes[x]);
  }
  return text;
};

var decodeSpecialCharText = function (text) {
  var el = document.createElement('div');
  el.innerText = el.textContent = text;
  text = el.innerHTML;
  return text;
};

//decoding the encoded char to special char &quot; ==> '"'
//eg: html editor encoding the double qoute to encoded char (&quot;)
var decodingEncodeChar = function (text) {
  var chars = ['"'];
  var codes = [/&quot;/g];

  for (var x = 0; x < codes.length; x++) {
    text = text.replace(codes[x], chars[x]);
  }

  return text;
};

export default QtiService;
