import { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { useAuth } from 'workspace/providers/auth/AuthContext';
import { createUser } from 'workspace/api/users.service';
import { updateUser } from 'workspace/api/users.service';
import { ROLES } from 'workspace/constants';
import { CustomModal } from 'common/components/CustomModal';
import Password from 'common/components/form/Password';
import { toastify } from 'common/components/Toastify';
import { useLoader } from 'common/providers/LoaderProvider';
import { useAutoFocus } from 'common/hooks/useAutoFocus';
import { getValidationFunction } from 'common/utils/validations';

const USER_FORM_VALIDATIONS = {
  firstName: {
    required: 'First Name is required',
  },
  lastName: {
    required: 'Last Name is required',
  },
  userName: {
    required: 'Username is required',
  },
  email: {
    required: 'Email Id is required',
    email: 'Please enter a valid Email Id (e.g., example@gmail.com)',
  },
  password: {
    required: 'Password is required',
    pattern: {
      value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).{8,}$/,
      errorMessage:
        'Your password must be at least 8 characters long and include a mix of uppercase and lowercase letters, numbers, and special characters.',
    },
  },
};

const validate = getValidationFunction(USER_FORM_VALIDATIONS);

/**
 * Gets the validation errors for the given form data.
 *
 * @param {Object} formData - The form data to validate.
 * @param {boolean} isEditUser  - Whether the user is being edited or created.
 * @returns {Object} - An object with error messages for each invalid field.
 */
const getValidationErrors = (formData, isEditUser) => {
  return Object.entries(formData).reduce((acc, [key, value]) => {
    // Skip validation for the 'roles' field and the 'password' field when editing a user and value is empty
    if (key === 'roles' || (isEditUser && key === 'password' && value === '')) return acc;

    // Validate the current field using the validateError function
    const error = validate(key, value);

    // If an error is found, add it to the errors object
    if (error) {
      acc[key] = error;
    }

    return acc;
  }, {});
};

const CreateUser = ({ handleClose, editUser, reload }) => {
  const { userData } = useAuth();
  const intl = useIntl();
  const autoFocusRef = useAutoFocus();

  const [formData, setFormData] = useState({
    firstName: editUser?.firstName || '',
    lastName: editUser?.lastName || '',
    userName: editUser?.userName || '',
    email: editUser?.email || '',
    password: '',
    roles: editUser ? editUser.role : userData.role === ROLES.SUPER_ADMIN ? ROLES.ADMIN : ROLES.AUTHOR,
  });
  const [errors, setErrors] = useState({});
  const [submitted, setSubmitted] = useState(false);
  const { showLoader, hideLoader } = useLoader();

  const handleChange = e => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    const validation = validate(name, value);
    setErrors(prev => ({ ...prev, [name]: validation || '' }));
  };

  const handleRoleChange = e => {
    setFormData({ ...formData, roles: Number(e.target.value) });
  };

  /**
   * Handles the submission of the create/edit user form.
   * Validates the form data, creates a new user if valid, and displays a success or error message.
   */
  const handleAddUser = async () => {
    setSubmitted(true); // Set submitted to true for error display

    // Validate the form data
    const validationErrors = getValidationErrors(formData, Boolean(editUser));

    // If there are validation errors, display them and exit
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    if (editUser) {
      try {
        showLoader();
        const { userId } = userData;
        const payload = {
          firstName: formData.firstName,
          lastName: formData.lastName,
          userName: formData.userName,
          email: formData.email,
          role: formData.roles,
          updatedBy: userId,
        };

        if (formData.password && formData.password.trim() !== '') {
          payload.password = formData.password;
        }

        await updateUser(editUser.id, payload);
        toastify.showSuccessToast('User updated successfully');
        handleClose();
        reload();
      } catch (error) {
        if (error.status === 409) {
          toastify.showWarningToast(intl.formatMessage({ id: 'ws.warning.duplicateUserName' }));
        } else {
          toastify.showErrorToast(error?.message);
        }
      } finally {
        hideLoader();
      }
    } else {
      try {
        showLoader();
        const { userId, workspaceId } = userData;
        const payload = {
          ...formData,
          wsId: workspaceId,
          createdBy: userId,
        };
        await createUser(payload);
        toastify.showSuccessToast('User created successfully');
        handleClose();
        reload();
      } catch (error) {
        if (error.status === 409) {
          toastify.showWarningToast(error?.message);
        } else {
          toastify.showErrorToast(error?.message);
        }
      } finally {
        hideLoader();
      }
    }
  };

  return (
    <CustomModal show={true} size="lg" className="create-user-modal">
      <CustomModal.Header onClose={handleClose}>
        <FormattedMessage
          id={editUser ? 'ws.edituser' : 'ws.usercreation'}
          defaultMessage={editUser ? 'Edit User' : 'User Creation'}
        />
      </CustomModal.Header>
      <CustomModal.Body>
        <Form>
          <Form.Group controlId="formFirstname" className="mb-2">
            <Form.Label className="ws-form-label">
              <FormattedMessage id="ws.firstname" />
              <span className="required-asterisk">*</span>
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter First Name"
              size="sm"
              name="firstName"
              value={formData.firstName}
              ref={autoFocusRef}
              onChange={handleChange}
              maxLength={100}
            />
            <div className="form-error-message">{submitted && errors.firstName}</div>
          </Form.Group>

          <Form.Group controlId="formLastname" className="mb-2">
            <Form.Label className="ws-form-label">
              <FormattedMessage id="ws.lastname" />
              <span className="required-asterisk">*</span>
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Last Name"
              size="sm"
              name="lastName"
              value={formData.lastName}
              onChange={handleChange}
              maxLength={100}
            />
            <div className="form-error-message">{submitted && errors.lastName}</div>
          </Form.Group>

          <Form.Group controlId="formUsername" className="mb-2">
            <Form.Label className="ws-form-label">
              <FormattedMessage id="ws.username" />
              <span className="required-asterisk">*</span>
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Username"
              size="sm"
              name="userName"
              value={formData.userName}
              onChange={handleChange}
              maxLength={100}
            />
            <div className="form-error-message">{submitted && errors.userName}</div>
          </Form.Group>

          <Form.Group controlId="formEmail" className="mb-2">
            <Form.Label className="ws-form-label">
              <FormattedMessage id="ws.email" />
              <span className="required-asterisk">*</span>
            </Form.Label>
            <Form.Control
              type="email"
              placeholder="Enter Email Id"
              size="sm"
              name="email"
              value={formData.email}
              onChange={handleChange}
              maxLength={100}
            />
            <div className="form-error-message">{submitted && errors.email}</div>
          </Form.Group>

          <Form.Group controlId="formPassword" className="mb-2">
            <Form.Label className="ws-form-label">
              <FormattedMessage id="ws.password" />
              <span className="required-asterisk">*</span>
            </Form.Label>
            <Password
              placeholder="Enter Password"
              name="password"
              size="sm"
              value={formData.password}
              onChange={handleChange}
              maxLength={100}
            />
            <div className="form-error-message">{submitted && errors.password}</div>
          </Form.Group>

          <Form.Group controlId="formRole">
            <div className="user-role">
              <span className="select-role">
                <FormattedMessage id="ws.userrole" />
              </span>
              {userData.role === ROLES.SUPER_ADMIN && (
                <Form.Check
                  inline
                  label="Admin"
                  name="roles"
                  type="radio"
                  value={ROLES.ADMIN}
                  checked={formData.roles === ROLES.ADMIN}
                  onChange={handleRoleChange}
                  disabled={editUser && userData.role !== ROLES.SUPER_ADMIN}
                />
              )}
              <Form.Check
                inline
                label="Author"
                name="roles"
                type="radio"
                value={ROLES.AUTHOR}
                checked={formData.roles === ROLES.AUTHOR}
                onChange={handleRoleChange}
                disabled={editUser && userData.role !== ROLES.SUPER_ADMIN}
              />
            </div>
          </Form.Group>
        </Form>
      </CustomModal.Body>
      <CustomModal.Footer>
        <Button variant="secondary" size="sm" onClick={handleClose}>
          <FormattedMessage id="message.cancel" />
        </Button>
        <Button variant="primary" size="sm" onClick={handleAddUser}>
          <FormattedMessage id="message.save" />
        </Button>
      </CustomModal.Footer>
    </CustomModal>
  );
};

export default CreateUser;
