import { useIdleTimer } from 'react-idle-timer';
import { Navigate } from 'react-router-dom';
import WSLayout from 'workspace/components/layout/WSLayout';
import { useAuth } from 'workspace/providers/auth/AuthContext';
import ManageQuestionsProvider from 'workspace/providers/manage-questions/ManageQuestionsProvider';

const ProtectedRoutes = () => {
  const { token, logout } = useAuth();

  const handleOnIdle = () => {
    logout();
  };

  // Convert the timeout from minutes to milliseconds
  const idleTimeout = Number(process.env.REACT_APP_IDLE_TIMEOUT_IN_MINUTES) * 60 * 1000;

  useIdleTimer({
    timeout: idleTimeout,
    onIdle: handleOnIdle,
    debounce: 500,
  });

  if (!token) {
    return <Navigate to="login" />;
  }

  return (
    <ManageQuestionsProvider>
      <WSLayout />
    </ManageQuestionsProvider>
  );
};

export default ProtectedRoutes;
