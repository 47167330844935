import { toast } from 'react-toastify';

const defaultOptions = {
  position: toast.POSITION.TOP_CENTER,
  autoClose: 3000,
  theme: 'colored',
  className: 'toast-right-to-left',
};

function showToast(type, message, options = {}) {
  const { className = '' } = options;
  const combinedClassName = className ? `${defaultOptions.className} ${className}` : defaultOptions.className;

  toast(message, { ...defaultOptions, type, className: combinedClassName });
}

export const toastify = {
  showSuccessToast: showToast.bind(this, 'success'),
  showWarningToast: showToast.bind(this, 'warning'),
  showErrorToast: showToast.bind(this, 'error'),
  showInfoToast: showToast.bind(this, 'info'),
};
