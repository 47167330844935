import { AxiosError } from 'axios';
import DOMPurify from 'dompurify';

/**
 * Extracts the error details from an Axios error object.
 *
 * @param {AxiosError} error - The Axios error object to extract the message from.
 * @returns {{ status: number, message: string }} The extracted error message and status.
 */
export const extractErrorDetails = error => {
  if (!(error instanceof AxiosError)) {
    return { status: null, message: error.message };
  }

  const { response } = error;
  let message = '';
  let status = null;

  if (response) {
    status = response.status;

    if (response.data && response.data.message) {
      message = response.data.message;
    } else {
      message = `Request failed with status code ${response.status}`;
    }
  } else {
    message = error.message;
  }

  return { status, message };
};

/**
 * Creates a deep clone of an object or value.
 *
 * @param {*} data The object or value to clone.
 * @returns {*} A deep clone of the input data.
 */
export const deepClone = data => {
  if (typeof data !== 'object' || data === null) {
    return data; // primitive types or null, no need to clone
  }
  return JSON.parse(JSON.stringify(data));
};

/**
 * Sanitizes HTML data using DOMPurify.
 *
 * @param {string} data The HTML data to sanitize.
 * @returns {{ __html: string }} An object with a single property `__html` containing the sanitized HTML.
 */
export const sanitizedData = data => ({
  __html: DOMPurify.sanitize(data),
});

/**
 * Formats a date string into the format DD/MM/YYYY.
 *
 * @param {string} dateString The date string to format.
 * @returns {string|null} The formatted date string, or null if the input is invalid.
 */
export const formatDate = dateString => {
  if (!dateString) return null;

  const date = new Date(dateString);
  if (isNaN(date.getTime())) return null;

  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();

  return `${month}/${day}/${year}`;
};

/**
 * Decodes special characters (HTML entities) in a string to their respective characters.
 *
 * @param {string} input - The string containing special characters or HTML entities.
 * @returns {string} - The decoded string with all special characters converted.
 */
export function decodeSpecialCharacters(input) {
  const parser = new DOMParser();
  const doc = parser.parseFromString(input, 'text/html');
  return doc.documentElement.textContent.trim();
}
