import axios from './axios';

/**
 * Fetches all workspaces.
 *
 * @returns {Promise} A promise that resolves to the response of the GET request.
 */
export async function getAllWorksapce() {
  return axios.get('ws/workspace');
}

/**
 * Adds a new workspace.
 *
 * @param {Object} workspaceData - The data for the new workspace.
 * @returns {Promise} A promise that resolves to the response of the POST request.
 */
export async function addWorkspace(workspaceData) {
  return axios.post('ws/workspace', workspaceData);
}

/**
 * Updates an existing workspace.
 *
 * @param {Object} workspaceData - The updated data for the workspace.
 * @param {string} workspaceId - The ID of the workspace to update.
 * @returns {Promise} A promise that resolves to the response of the PUT request.
 */
export async function updateWorkspace(workspaceData, workspaceId) {
  return await axios.put(`/ws/workspace/${workspaceId}`, workspaceData);
}

/**
 * Deletes a workspace.
 *
 * @param {string} workspaceId - The ID of the workspace to delete.
 * @returns {Promise} A promise that resolves to the response of the DELETE request.
 */
export async function deleteWorkspace(workspaceId) {
  return axios.delete(`/ws/workspace/${workspaceId}`);
}
