import React, { useEffect, useMemo, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import TBHeader from '../layouts/TBHeader';
import SearchBox from 'common/components/SearchBox/SearchBox';
import { toastify } from 'common/components/Toastify';
import { useAppContext } from '../../context/AppContext';
import { getDisciplineBooks, saveUserBooks } from '../../services/book.service';
import { saveUserDiscipline } from '../../services/discipline.service';
import './Booktab.css';

const LeftContent = () => {
  return (
    <div className="left-content">
      <span className="left-span">
        <FormattedMessage id="booktab.steps.2" />
      </span>
      <ul>
        <li>
          <FormattedMessage id="booktab.steps.3" />
        </li>
        <li>
          <FormattedMessage id="booktab.steps.4" />
        </li>
        <li>
          <FormattedMessage id="booktab.steps.5" />
        </li>
      </ul>
    </div>
  );
};

const TreeNode = ({ node, onSelectItem, selectedItems, searchTerm }) => {
  const [isOpen, setIsOpen] = useState(true);
  const hasChildNodes = node.nodes && node.nodes.length > 0;
  const [isSelected, setIsSelected] = useState(selectedItems.includes(node.id));

  useEffect(() => {
    setIsSelected(selectedItems.includes(node.id));
  }, [selectedItems, node.id]);

  const handleNodeClick = () => {
    setIsOpen(!isOpen);
  };

  const handleSelectNode = () => {
    if (!hasChildNodes) {
      onSelectItem(node);
      setIsSelected(!isSelected);
    }
  };
  const isHeaderDisciplineNode = () => {
    return node.droppable && node.nodes && node.nodes.length > 0;
  };

  const shouldRenderNode = () => {
    return !isHeaderDisciplineNode() || searchTerm === '';
  };

  return (
    <div>
      {shouldRenderNode() && (
        <div
          className={`tree-node ${hasChildNodes ? '' : isSelected ? 'selected' : ''}`}
          onClick={hasChildNodes ? handleNodeClick : handleSelectNode}
          tabIndex="0"
        >
          {hasChildNodes && (
            <div className="tree-node-header">
              {isOpen ? <i className="fa fa-caret-down"></i> : <i className="fa fa-caret-right"></i>}
            </div>
          )}
          <span>{node.text}</span>
        </div>
      )}

      <div>
        {isOpen && hasChildNodes && (
          <div className="nested-nodes">
            {node.nodes.map(childNode => (
              <div key={childNode.id}>
                <TreeNode
                  node={childNode}
                  onSelectItem={onSelectItem}
                  selectedItems={selectedItems}
                  searchTerm={searchTerm}
                />
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

const TreeView = ({ selectedItems, onSelectItem, searchTerm, treeData }) => {
  const filterNodes = (nodes, term, isBookNode) => {
    return nodes.flatMap(node => {
      const filteredChildNodes = filterNodes(node.nodes || [], term, isBookNode || node.droppable);
      if (filteredChildNodes.length > 0) {
        return [{ ...node, nodes: filteredChildNodes }];
      }
      if (isBookNode && node.text.toLowerCase().includes(term.toLowerCase())) {
        return [{ ...node, nodes: [] }];
      }
      return [];
    });
  };

  const filteredTreeData = useMemo(() => {
    if (!searchTerm) {
      return treeData;
    }
    return filterNodes(treeData, searchTerm, false);
  }, [searchTerm, treeData]);

  return (
    <div className="treeview">
      {searchTerm && filteredTreeData.length === 0 ? (
        <div className="no-matching-books-message" tabIndex="0">
          <FormattedMessage id="message.noMatchingBooksFound" defaultMessage="No matching books found" />
        </div>
      ) : (
        filteredTreeData.map(node => (
          <TreeNode
            key={node.id}
            node={node}
            onSelectItem={onSelectItem}
            selectedItems={selectedItems}
            searchTerm={searchTerm}
          />
        ))
      )}
    </div>
  );
};

const Booktab = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const { dispatchEvent } = useAppContext();

  const [searchTerm, setSearchTerm] = useState('');
  const [selectedBooks, setSelectedBooks] = useState([]);
  const [bookDetails, setBookDetails] = useState([]);
  const [treeData, setTreeData] = useState([]);
  const prevDisciplines = useRef([]);
  const disciplines = sessionStorage.getItem('selectedDiscipline');
  const selectedDisciplines = disciplines ? JSON.parse(disciplines) : [];

  useEffect(() => {
    document.title = 'Choose Your Books or Topics';
  }, []);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      dispatchEvent('SHOW_LOADER');
      if (disciplines) {
        if (JSON.stringify(selectedDisciplines) !== JSON.stringify(prevDisciplines.current)) {
          prevDisciplines.current = selectedDisciplines;
          let newData = [];
          let uniqueDisciplines = new Set();
          for (const setofItem of selectedDisciplines) {
            const data = await getDisciplineBooks(setofItem);
            const formattedData = data.reduce((acc, item) => {
              if (!uniqueDisciplines.has(item.discipline)) {
                uniqueDisciplines.add(item.discipline);
                acc.push({
                  id: item.guid,
                  text: `${item.discipline}`,
                  droppable: true,
                  nodes: data
                    .filter(title => title.discipline === item.discipline)
                    .map(title => ({
                      id: `${title?.guid}`,
                      text: `${title.title}`,
                      droppable: false,
                      parentId: item.guid,
                    })),
                });
              }
              return acc;
            }, []);

            newData = [...newData, ...formattedData];
          }
          setTreeData(newData);
        }
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      dispatchEvent('HIDE_LOADER');
    }
  };

  const handleNext = async () => {
    try {
      const selectedbookIds = bookDetails.map(book => book.id);

      await saveUserDiscipline(selectedDisciplines);
      await saveUserBooks(selectedbookIds);

      if (selectedBooks.length > 0) {
        navigate(`/home?books=${bookDetails.join(',')}`);
      }
    } catch (error) {
      toastify.showErrorToast(error?.message);
    }
  };

  const handleBack = () => {
    navigate('/discipline');
  };

  const handleSearch = searchValue => {
    setSearchTerm(searchValue);
  };

  useEffect(() => {
    const storedSelectedBooks = sessionStorage.getItem('selectedBooks');
    if (storedSelectedBooks) {
      setSelectedBooks(JSON.parse(storedSelectedBooks));
    }
  }, [sessionStorage.getItem('selectedBooks')]);

  const handleSelectItem = node => {
    if (!node.droppable) {
      const bookDetail = {
        id: `${node.id}`,
        title: node.text,
        discipline: treeData.find(item => item.id === node.parentId)?.text,
      };
      const isBookSelected = selectedBooks.includes(bookDetail.id);
      if (isBookSelected) {
        setSelectedBooks(prevSelectedBooks => prevSelectedBooks.filter(item => item !== bookDetail.id));
        setBookDetails(prevBookDetails => prevBookDetails.filter(book => book.id !== bookDetail.id));
      } else {
        setSelectedBooks(prevSelectedBooks => [...prevSelectedBooks, bookDetail.id]);
        setBookDetails(prevBookDetails => [...prevBookDetails, bookDetail]);
      }
    }
  };

  return (
    <>
      <TBHeader />
      <div className="booktab-container">
        <div className="top-container">
          <h2 className="choose-your-books-or-topics">
            <FormattedMessage id="booktab.steps.1" />
          </h2>
          <button className="booktab btn btn-secondary" onClick={handleBack}>
            <FormattedMessage id="backBooktabtext" defaultMessage="Back" />
          </button>
          <button className="booktab btn btn-primary" disabled={selectedBooks.length === 0} onClick={handleNext}>
            <FormattedMessage id="message.next" />
          </button>
        </div>
        <div className="booktab d-flex justify-content-between">
          <LeftContent />

          <div className="discipline search-container" tabIndex="0">
            <SearchBox placeholder={intl.formatMessage({ id: 'message.searchBook' })} onSearch={handleSearch} />

            <ul className="discipline result-list mt-1">
              <TreeView
                selectedItems={selectedBooks}
                onSelectItem={handleSelectItem}
                searchTerm={searchTerm}
                treeData={treeData}
              />
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default Booktab;
