import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from 'workspace/providers/auth/AuthContext';
import { ROLES } from 'workspace/constants';

const RoleBasedRoutes = ({ children, allowedRoles }) => {
  let location = useLocation();
  const { userData } = useAuth();

  // Check if the user is authorized based on their role
  const isUserAuthorized = userData && allowedRoles?.includes(userData.role);

  // Check if the user is a SUPER_ADMIN without an associated workspace
  const isSuperAdminWithoutWorkspace = userData?.role === ROLES.SUPER_ADMIN && !userData.workspaceId;

  // If the user is not authorized, handle the unauthorized access
  if (!isUserAuthorized) {
    return handleUnauthorizedAccess(userData.role);
  }

  // If the user is a SUPER_ADMIN without a workspace and not on the manage workspace page, redirect them
  if (isSuperAdminWithoutWorkspace && location.pathname !== '/ws/manageworkspace') {
    return <Navigate to="/ws/manageworkspace" />;
  }

  // If the user is authorized, render the children components
  return children;
};

// Function to handle unauthorized access based on user role
const handleUnauthorizedAccess = role => {
  switch (role) {
    case ROLES.ADMIN:
      // Redirect ADMIN users to the manage users page
      return <Navigate to="/ws/manageusers" />;
    default:
      // Redirect all other users to the manage books page
      return <Navigate to="/ws/managebooks" />;
  }
};

export default RoleBasedRoutes;
