import axios from './axios';
import { ROLES } from 'workspace/constants';

/**
 * Creates a new user.
 *
 * @param {Object} data - The user data to be created.
 * @returns {Promise} - A promise that resolves to the response of the API call.
 */
export async function createUser(data) {
  return axios.post('ws/users', data);
}

/**
 * Updates an existing user.
 *
 * @param {string} userId - The ID of the user to be updated.
 * @param {Object} data - The new data for the user.
 * @returns {Promise} - A promise that resolves to the response of the API call.
 */
export async function updateUser(userId, data) {
  return axios.put(`/ws/users/${userId}`, data);
}

/**
 * Retrieves a list of users based on the specified role and workspace ID.
 *
 * @param {string} role - The role of the user (e.g., ADMIN).
 * @param {string} userId - The ID of the user making the request.
 * @param {string} wsid - The ID of the workspace.
 * @returns {Promise} - A promise that resolves to the response of the API call.
 */
export async function getUsers(role, userId, wsid) {
  return axios.get(`ws/${wsid}/users${role === ROLES.ADMIN ? '?createdBy=' + userId : ''}`);
}

/**
 * Deletes a user from the workspace.
 *
 * @param {string} userId - The ID of the user to be deleted.
 * @returns {Promise} - A promise that resolves to the response of the API call.
 */
export async function deleteUserFromWorkspace(userId) {
  return axios.delete(`ws/users/${userId}`);
}
