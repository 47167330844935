import { jwtDecode } from 'jwt-decode';
import { apiClient } from 'workspace/api/axios';

export const AUTH_ACTIONS = {
  LOGIN_SUCCESS: 'LOGIN_SUCCESS', // Action type for successful login
  LOGOUT: 'LOGOUT', // Action type for logout
};

/**
 * Authentication reducer function
 *
 * @param {object} state - Current state of the authentication
 * @param {object} action - Action to be performed on the state
 * @returns {object} New state of the authentication
 */
export const authReducer = (state, action) => {
  switch (action.type) {
    // Handle successful login action
    case AUTH_ACTIONS.LOGIN_SUCCESS: {
      const { token, refreshToken } = action.payload;
      const userData = jwtDecode(token);

      localStorage.setItem('wstoken', token);
      localStorage.setItem('wsRefreshToken', refreshToken);
      localStorage.setItem('wsuser', JSON.stringify(userData));

      // Update the state with the new token
      return { ...state, token, userData };
    }

    // Handle logout action
    case AUTH_ACTIONS.LOGOUT:
      // Clear the authentication token from axios headers and session storage
      delete apiClient.defaults.headers.common['Authorization'];
      localStorage.removeItem('wstoken');
      localStorage.removeItem('wsuser');

      // Update the state by removing the token
      return { ...state, token: null, userData: null };

    case AUTH_ACTIONS.UPDATE_ACTIVE_WORKSPACE: {
      const { workspaceId, workspaceName } = action.payload;
      const userData = { ...state.userData, workspaceId: workspaceId, workspaceName: workspaceName };

      // Update the local storage with the new workspace data
      localStorage.setItem('wsuser', JSON.stringify(userData));

      return {
        ...state,
        userData,
      };
    }

    default:
      console.error(`You passed an action.type: ${action.type} which doesn't exist`);
      return state;
  }
};
